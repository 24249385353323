<template>
  <div>
    <CRow class=" justify-content-center">
      <CCol lg="8">
        <CCard class="rounded-lg">
          <CCardHeader>
            <strong class="h5">Send Money</strong>
            <p>
              Enter the details of your transaction.
            </p>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="initiateTransfer">
              <div class="form-group">
                <label for="bank">Banks</label>
                <select
                  name="banks"
                  class="form-control"
                  v-model="bank_id"
                  @change="getReceiverBankDetails()"
                  :disabled="load_data"
                >
                  <option v-if="load_data" selected disabled value="">
                    Getting banks...
                  </option>
                  <option value="" selected>Select banks</option>
                  <option v-for="bank in banks" :value="bank.id" :key="bank.id">
                    {{ bank.bank_name }}</option
                  >
                </select>
              </div>

              <div class="form-group">
                <label for="account_number">Account Number</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="account_number"
                  @keyup="getReceiverBankDetails()"
                />
              </div>
              <div class="form-group">
                <label for="account_number">Account Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="receiver_account_name"
                  :readonly="isReadOnly"
                  v-model="receiver_account_name"
                />
              </div>
              <div class="form-group">
                <label for="account_number">Amount</label>
                <input type="text" class="form-control" v-model="amount" />
              </div>
              <!-- <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="saveToBeneficiary"
                  id="saveBen"
                />
                <label class="form-check-label" for="terms-agree">
                  Save to beneficiary
                </label>
              </div> -->
              <CButton
                color="primary"
                class="px-4 float-right"
                type="submit"
                :disabled="loading"
                size="lg"
              >
                <span v-if="loading"
                  ><loader-icon size="1.5x" class="custom-class"></loader-icon
                  >Please Wait</span
                >
                <span v-else>Continue</span>
              </CButton>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/services/api.service";
export default {
  name: "SendToBank",
  data() {
    return {
      user: {},
      banks: [],
      saveToBeneficiary: false,
      amount: "",
      account_number: "",
      receiver_account_name: "",
      bank_id: "",
      load_data: false,
      loading: false,
      getReceiverBankName: false,
    };
  },
  computed: {
    isReadOnly() {
      return this.receiver_account_name !== "";
    },
  },
  created() {
    this.getBanks();
  },
  methods: {
    async getBanks() {
      this.load_data = true;
      try {
        const response = await ApiService.get("/transfer/get-banks");
        console.log(response);
        this.load_data = false;
        this.banks = response.data.data;
      } catch (error) {
        this.load_data = false;
        let _error = error.response.data;
        // console.log(_error);
        Swal.fire({
          title: "Error!",
          text: _error.data,
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    },
    async getReceiverBankDetails() {
      console.log("inside");
      this.receiver_account_name = "";
      if (this.account_number.length > 9 && this.bank_id !== "") {
        this.receiver_account_name = "Getting Account Number ...";
        let data = {
          account_number: this.account_number,
          bank_id: this.bank_id,
        };
        this.getReceiverBankName = true;

        try {
          const response = await ApiService.post(
            "/transfer/get-bank-account-details",
            data
          );
          console.log(response);
          this.getReceiverBankName = false;
          this.receiver_account_name = response.data.message;
        } catch (error) {
          this.getReceiverBankName = false;
          this.receiver_account_name = "";
          let _error = error.error.data || error.statusText;
          Swal.fire({
            title: "Error!",
            text: _error,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
      } else {
        this.receiver_account_name = "";
      }
    },
    async initiateTransfer() {
      let data = {
        amount: this.amount,
        account_number: this.account_number,
        bank_id: this.bank_id,
      };
      this.loading = true;
      try {
        const response = await ApiService.post(
          "/transaction/initiate-funds-transfer",
          data
        );
        this.loading = false;
        console.log(response);
        this.$store.commit("setCreateTransactionObject", response.data.data);
        this.$nextTick(() => this.$router.push({ name: "ProcessTransaction" }));
      } catch (error) {
        this.loading = false;
        let _error = error.response.data;
        Swal.fire({
          title: "Error!",
          text: _error.data,
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    },
  },
  // mounted() {
  //   this.getBanks();
  // },
};
</script>

<style scoped></style>
